import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { LocalStorage } from 'ts-localstorage'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../components/UI/Logos/Logo'
import TransparentButton from '../../components/UI/TransparentButton/TransparentButton'
import { accountApi } from '../../store/api/account.api'
import { ADMIN_ACCOUNT_ID, ADMIN_USER_ID, SIGN_IN_ROUTE, SIGN_UP_ROUTE } from '../../config/consts'
import { useTranslation } from 'react-i18next'
import { useAppConfig } from '../../hooks/appHook'
import { userResetState } from '../../store/slices/appState.slice'

const TopFloater = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation('guest')

  const { appFeatures } = useAppConfig()

  const isSignin = location.pathname.includes('signin')

  const handleAuth = () => {
    if (!appFeatures?.signupEnabled && isSignin) {
      return window.open(t('topFloater.signupLink'), '_blank')?.focus()
    }

    const adminUserId = LocalStorage.getItem(ADMIN_USER_ID)
    const adminAccountId = LocalStorage.getItem(ADMIN_ACCOUNT_ID)

    if (adminAccountId || adminUserId) {
      LocalStorage.removeItem(ADMIN_USER_ID)
      LocalStorage.removeItem(ADMIN_ACCOUNT_ID)
    } else {
      LocalStorage.clear()
    }
    dispatch(userResetState())
    dispatch(accountApi.util.resetApiState())

    if (isSignin) {
      return navigate(SIGN_UP_ROUTE)
    }

    return navigate(SIGN_IN_ROUTE)
  }

  return (
    <div className="top-floater">
      <a href="https://www.riece.ai/" target="_blank" className="logo-container" rel="noreferrer">
        <Logo name={'Riece'} width={'100%'} height={'100%'} />
      </a>
      <div className="top-floater-right">
        <div className="top-floater-right-text">
          {isSignin ? t('topFloater.dontHaveAccountYet') : t('topFloater.alreadyHaveAccount')}
        </div>
        <TransparentButton title={isSignin ? t('topFloater.signUp') : t('topFloater.signIn')} onClick={handleAuth} />
      </div>
    </div>
  )
}

export default memo(TopFloater)
